
import { defineComponent, ref } from 'vue'

import Nav from '../../components/Nav/Nav.vue'
import FooterNav from '../../components/Footer/FooterNav.vue'
import ArticleInfoRCard from '../article/cnps/ArticleInfoRCard.vue'
import ArticleList from '../article/ArticleList.vue'

export default defineComponent({
  name: 'ArticleMain',
  components: {
    Nav,
    FooterNav,
    ArticleInfoRCard,
    ArticleList,
  },
  setup(props, content) {},
})
