
import { defineComponent ,ref,watchEffect} from 'vue'
import { Stopwatch, View, ChatDotRound, Star } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'ArticleTop5List',
  props: ['data','type'],
  components: {
    Stopwatch,
    View,
    ChatDotRound,
    Star,
  },
  setup(props, content) {
    let num=ref(0)
    let type=ref('')
    watchEffect(()=>{
        if(props.type){
            switch(props.type){
                case 'articleTopByViewsType':
                    num.value=props.data.articleViews
                    type.value='articleTopByViewsType'
                    break
                case 'articleTopByStarsType':
                    num.value=props.data.articleStar
                    type.value='articleTopByStarsType'
                    break
                case 'articleTopByCommentsType':
                    num.value=props.data.articleComments
                    type.value='articleTopByCommentsType'
                    break
            }
        }
    })
    return{
        num,
        type
    }
  },
})
