import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ArticleCataItem" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.cataData.success)
      ? (_openBlock(), _createBlock(_component_el_tree, {
          key: 0,
          "default-expand-all": true,
          "expand-on-click-node": false,
          data: _ctx.cataData.data.cataList,
          props: _ctx.defaultProps,
          onNodeClick: _ctx.clickCata
        }, null, 8, ["data", "props", "onNodeClick"]))
      : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.cataData.msg), 1))
  ]))
}