
import { defineComponent, ref } from 'vue'
import type { UploadProps, UploadFile } from 'element-plus'
import  message  from '../../utils/message'
import localCache from '../../utils/cache'

import {BASE_URL} from '../../service/request/config'

export default defineComponent({
  name: 'Avatar',
  emits: ['coverImgEmit'],
  props:{
    imgUrl:{}
  },
  setup(props, content) {
    const circleUrl = ref()
    const action = BASE_URL+'/cos/upload'
    const handleRemove: UploadProps['onRemove'] = (uploadFile, uploadFiles) => {
      console.log(uploadFile, uploadFiles)
    }
    const dialogVisible = ref(false)
    const handlePictureCardPreview = () => {
      dialogVisible.value = true
    }
    const handleAvatarSuccess: UploadProps['onSuccess'] = (response, uploadFile) => {
      if (response.success) {
        circleUrl.value = response.data.fileBaseUrl + response.data.filePath
        console.log(response.data)
        message.success('上传成功')
        content.emit('coverImgEmit', circleUrl.value)
      } else {
        message.error(response.data)
      }
    }
    const beforeAvatarUpload: UploadProps['beforeUpload'] = (rawFile) => {
      if (rawFile.type !== 'image/jpeg') {
        message.warning('请上传jpeg、jpg、png')
        return false
      } else if (rawFile.size / 1024 / 1024 > 2) {
        message.error('图片大小不能超过2MB')
        return false
      }
      return true
    }
    const headerObj = { token: 'zzl_' + localCache.getCache('token') }
    //是否是编辑文章
    const init=()=>{
      if(props.imgUrl){
        circleUrl.value=props.imgUrl
      }
    }
    init()
    return {
      circleUrl,
      handleRemove,
      action,
      headerObj,
      handleAvatarSuccess,
      handlePictureCardPreview,
      dialogVisible,
    }
  },
})
