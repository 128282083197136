
import { defineComponent ,onMounted,ref,watchEffect} from 'vue'
import {useRouter} from 'vue-router'

import {getAuthorCollect} from '../../../service/user/user'

import message from '../../../utils/message'

export default defineComponent({
  name: 'CollectItem',
  props:['authorData'],
  setup(props,content){
    let collectData=ref({})
    const router = useRouter()
    async function getAuthorCollectFun() {
      const data=await getAuthorCollect(props.authorData.userId)
      if(data.success){
        collectData.value=data.data
      }else{
        message.error(data.data)
      }
    }
    watchEffect(()=>{
      if(props.authorData&&props.authorData.userId){
        getAuthorCollectFun()
      }
    })
    const goAuthor = () => {
      router.push({
        name: 'usermenu',
        path: '/usermenu',
        params: {
          userid: props.authorData.userId,
        },
      })
    }
    return{
      collectData,
      goAuthor
    }
  }
})
