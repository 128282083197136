import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04ea89f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "CommentReply" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReplyOfComment = _resolveComponent("ReplyOfComment")!
  const _component_CommentReply = _resolveComponent("CommentReply", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["commentBox", _ctx.deep === 1 ? 'replyItem' : 'commentItem'])
    }, [
      (!_ctx.isDestroy)
        ? (_openBlock(), _createBlock(_component_ReplyOfComment, {
            key: 0,
            onDestroyCommentEmit: _ctx.destroyComment,
            onIsReplyOkEmit: _ctx.isReplyOk,
            comment: _ctx.comment,
            present: _ctx.present2,
            isReply: _ctx.isReply
          }, null, 8, ["onDestroyCommentEmit", "onIsReplyOkEmit", "comment", "present", "isReply"]))
        : _createCommentVNode("", true),
      (_ctx.isShowVirComment)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.isDelVirCommentFlag>0)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.virReplyCommentList, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "replyItem"
                  }, [
                    (!_ctx.delVirCommentMap.has(index))
                      ? (_openBlock(), _createBlock(_component_ReplyOfComment, {
                          key: 0,
                          present: _ctx.present,
                          virIndex: index,
                          isVirComment: true,
                          onVirReplyDelEmit: _ctx.VirReplyDel,
                          comment: item,
                          isReply: true
                        }, null, 8, ["present", "virIndex", "onVirReplyDelEmit", "comment"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      (!_ctx.isDestroy)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.reply, (item) => {
            return (_openBlock(), _createBlock(_component_CommentReply, {
              key: item.commentId,
              reply: item.children,
              comment: item,
              isReply: true,
              present2: !item.children.length ? _ctx.present2 : item.author,
              deep: _ctx.deep + 1
            }, null, 8, ["reply", "comment", "present2", "deep"]))
          }), 128))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}