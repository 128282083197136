
import { defineComponent, ref ,watchEffect,onBeforeMount} from 'vue'
import { getCategorys } from '../../service/article/index'
import { Edit } from '@element-plus/icons-vue'
import CreageCate from './CreateCate.vue'
import message from '../../utils/message'
import type { FormInstance } from 'element-plus'

// import {defaultCateID} from './config/defaultCateID'

export default defineComponent({
  name: 'Category',
  components: {
    CreageCate,
  },
  emits: ['changeCateEmit'],
  props:{
    editCateName:{},
    editCateId:{},
  },
  setup(props, content) {
    let categorysArray = ref([])
    const changeCateForm = ref({
      categoryName: '默认',
      categoryID:'2834933a448ce3fd8a4caa7cbdb6d3d2'
    })
    let flag=true
    //隐藏选择分类表单，避免显示收缩下拉框
    let changeCateInputView = ref(true)
    //获取分类
    async function categorys() {
      const data = await getCategorys()
      if (data.success) {
        categorysArray.value = data.data
      } else {
        message.warning(data.data)
      }
    }
    categorys()
    //创建分类
    const centerDialogVisible = ref(false)
    //父分类ID
    const createCateParentID = ref('')
    //创建子分类ID
    const createCateChildrenID = ref('')
    const createCateDialog = (id: string) => {
      changeCateInputView.value = false
      centerDialogVisible.value = true
      createCateParentID.value = id
    }
    //接收CreagteCate创建好的子分类的ID
    const createOKCate = (data: any) => {
      centerDialogVisible.value = false
      changeCateInputView.value = false
      createCateChildrenID.value = data.categoryId
      changeCateForm.value = {
        categoryName:data.categoryName,
        categoryID:data.categoryId
      }
      // changeCateForm.value.cateId=data.categoryId
      changeCate()
    }

    //传给文章父组件分类ID
    const changeCate = () => {
      flag=false
      content.emit('changeCateEmit', changeCateForm.value)
    }
    //监听是否是编辑文章
    watchEffect(()=>{
      if(props.editCateName && flag){
        changeCateForm.value.categoryName=props.editCateName as string
        changeCateForm.value.categoryID=props.editCateId as string
      }
    })
    return {
      categorysArray,
      changeCateForm,
      changeCate,
      Edit,
      createCateParentID,
      createCateDialog,
      centerDialogVisible,
      createOKCate,
      changeCateInputView,
    }
  },
})
