
import { defineComponent } from 'vue'
import Nav from '../../components/Nav/Nav.vue'
// import Slide from '../article/Slide.vue'
import ArticleList from '../article/ArticleList.vue'

export default defineComponent({
  components: {
    Nav,
    // Slide,
    ArticleList,
  },
  setup(props, content) {},
})
