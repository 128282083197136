
import { defineComponent, watchEffect } from 'vue'

import LRCard from '../../../components/LRCard/LRCard.vue'
import ArticleCataItem from './ArticleCataItem.vue'
import CollectItem from './CollectItem.vue'

export default defineComponent({
  name: 'ArticleInfoRCard',
  props: ['cataData','authorData'],
  emits:['clickCataEmit'],
  components: {
    LRCard,
    ArticleCataItem,
    CollectItem
  },
  setup(props, content) {
    const cataItemFun=(hName:string)=>{
        content.emit('clickCataEmit',hName)
    }
    return{
        cataItemFun
    }
  },
})
