
import { defineComponent, ref, nextTick} from 'vue'
import { useStore } from '../../store/index'
import { useRoute, useRouter } from 'vue-router'

import message from '../../utils/message'

import { formatUtcString } from '../../utils/date'

import { addComment } from '../../service/comment/index'

import { getCommentStar, getDelComment } from '../../service/comment/index'

import { Comment, Star, Stopwatch, DeleteFilled } from '@element-plus/icons-vue'
import {ElInput} from 'element-plus'

export default defineComponent({
  name: 'ReplyOfComment',
  props: ['comment', 'isReply', 'present', 'isVirComment', 'virIndex'],
  emits: ['isReplyOkEmit', 'destroyCommentEmit', 'VirReplyDelEmit'],
  components: {
    Comment,
    Star,
    Stopwatch,
    DeleteFilled,
  },
  setup(props, content) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    let isReplyInput = ref(false)
    let parentCommentId = ''
    let replyInputContent = ref('')
    let starNum = ref(0)
    let isClickSubBtn=false
    const inputRef=ref<InstanceType<typeof ElInput>>()
    starNum.value = props.comment.commentStar

    //是否已经登录
    const isLogin = () => {
      if (!store.state.user.userInfo) {
        router.push('/login')
      }
    }

    const replyFun = (parentCommentID: string) => {
      isLogin()
      isReplyInput.value = !isReplyInput.value
      parentCommentId = parentCommentID
      nextTick(()=>{
        inputRef.value?.focus()
      })
    }
    const nickNameFun = (nickName: string) => {
      return nickName ? nickName : '路人甲'
    }
    //提交回复
    const submitReplyFun = () => {
      isLogin()
      if (replyInputContent.value) {
        submitComment()
      } else {
        return message.warning('请输入回复内容')
      }
    }
    async function submitComment() {
      const data = await addComment({
        articleId: route.params.id as string,
        commentContent: replyInputContent.value,
        parentCommentId: parentCommentId,
      })
      if (data.success) {
        message.success('回复成功')
        isReplyInput.value = false
        content.emit('isReplyOkEmit', {
          replyer: store.state.user.userInfo,
          byReplyer: nickNameFun(props.comment.author.nickName),
          user: data.data,
          commentContent: replyInputContent.value,
        })
        replyInputContent.value = ''
        //提交成功,隐藏输入框
        // isReplyInput.value=!isReplyInput.value
      } else {
        message.error(data.data)
      }
    }
    //点赞
    const commentStarFun = (commentId: string, isUnStar: boolean) => {
      commentStar(commentId, isUnStar)
    }
    async function commentStar(commentId: string, isUnStar: boolean) {
      const data = await getCommentStar(commentId, isUnStar)
      if (data.success) {
        if (isUnStar) {
          starNum.value--
          message.success('取消点赞成功')
        } else {
          starNum.value++
          message.success('点赞成功')
        }
      } else {
        message.error(data.data)
      }
    }
    //删除评论
    const delCommentFun = (commentId: string) => {
      delComment(commentId)
    }
    async function delComment(commentId: string, isDelVirComment = false) {
      const data = await getDelComment(commentId)
      if (data.success) {
        message.success('删除评论成功')
        if (isDelVirComment) {
          content.emit('VirReplyDelEmit', props.virIndex)
        } else {
          content.emit('destroyCommentEmit', true)
        }
      } else {
        message.error(data.data)
      }
    }
    //删除虚拟评论
    const delVirCommentFun = (commentId: string) => {
      delComment(commentId, true)
    }
    const test=()=>{
      console.log('切换焦点')
      // isReplyInput.value=!isReplyInput.value
    }
    const goAuthor=(userid:string)=>{
      router.push({
        name:'usermenu',
        path:'/usermenu',
        params:{
          userid
        }
      })
    }
    return {
      replyFun,
      nickNameFun,
      formatUtcString,
      replyInputContent,
      isReplyInput,
      submitReplyFun,
      commentStarFun,
      starNum,
      delCommentFun,
      delVirCommentFun,
      inputRef,
      test,
      goAuthor
    }
  },
})
