
import { defineComponent, ref, watchEffect ,onMounted} from 'vue'
import ArticleActive from './ArticleActive.vue'
import { User } from '@element-plus/icons-vue'

import {debounce} from '../../utils/debounce'

export default defineComponent({
  name: 'ArticleHeader',
  components: {
    ArticleActive,
    User
  },
  props: ['article'],
  setup(props, content) {
    let articleData = ref('')
    let isShowPhone=ref(false)
    let articleHeaderRef=ref<HTMLDivElement>()
    window.addEventListener('resize',function(){
      computedInnerWidthDebounce()
      })
    watchEffect(() => {
      articleData.value = props.article
    })
    const computedInnerWidth=()=>{
      if(window.innerWidth<=1000){
        isShowPhone.value=true
      }else{
        isShowPhone.value=false
      }
    }
    const computedInnerWidthDebounce= debounce(computedInnerWidth,1000,false)
    onMounted(()=>{
      computedInnerWidth()
    })
    return {
      articleData,
      articleHeaderRef,
      isShowPhone
    }
  },
})
