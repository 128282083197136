
import { defineComponent ,computed,onMounted,ref} from 'vue'
import LRCard from '../../components/LRCard/LRCard.vue'
import ArticleTop5List from './ArticleTop5List.vue'

import {useRoute} from 'vue-router'

import { useStore } from '../../store'

export default defineComponent({
  name: 'ArticleLRCard',
  components: {
    LRCard,
    ArticleTop5List
  },
  setup(props, content) {
    const store = useStore()
    const route=useRoute()
    let bev=ref('')
    const articleTopByViews= computed(()=>{
      return store.state.articleBy.articleTopByViews
    })
    const articleTopByStars= computed(()=>{
      return store.state.articleBy.articleTopByStars
    })
    const articleTopByComments= computed(()=>{
      return store.state.articleBy.articleTopByComments
    })
    const initLRArticleList = () => {
      if (!store.state.articleBy.articleTopByViews.length) {
        store.dispatch('articleBy/getArticleByList', { limit: 5, orderBy: 'articleViews' })
        store.dispatch('articleBy/getArticleByList', { limit: 5, orderBy: 'articleComments' })
        store.dispatch('articleBy/getArticleByList', { limit: 5, orderBy: 'articleStar' })
      }
    }
    initLRArticleList()
    const init=()=>{
      let hrefList=String(location.hostname).split('.')
      if(hrefList[0]==='zhifou'){
        bev.value='豫ICP备19046328号-1'
      }else{
        bev.value='豫ICP备20010557号-2'
      }
    }
    onMounted(()=>{
      init()
    })
    return{
      articleTopByViews,
      articleTopByStars,
      articleTopByComments,
      bev
    }
  },
})
