
import { defineComponent } from 'vue'
import LRCard from '../../components/LRCard/LRCard.vue'
import Chat from '../chat/Chat.vue'
import AuthorList from '../user/cpns/AuthorList.vue'

export default defineComponent({
  name: 'ArticleLCard',
  components:{
      LRCard,
      Chat,
      AuthorList
  },
  setup(props,content){
    
  }
})
