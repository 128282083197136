
import { defineComponent, ref } from 'vue'
import { getArticleTop } from '../../service/article/index'
import message  from '../../utils/message'

export default defineComponent({
  name: 'Slide',
  setup(props, content) {
    let articleTopAll = ref<any>([])
    async function getArticle() {
      const artiicle = await getArticleTop({ orderBy: 'articleViews.desc' })
      if (artiicle.success) {
        articleTopAll.value.push(...artiicle.data)
      } else {
        message.error(artiicle.data)
      }
    }
    getArticle()
    return {
      articleTopAll,
    }
  },
})
