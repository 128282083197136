
import { defineComponent, ref ,nextTick} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { addComment, getComment } from '../../service/comment/index'
import { IDataType } from '../../service/comment/type'
import { ElInput} from 'element-plus'
import message from '../../utils/message'
import CommentReply from './CommentReply.vue'

export default defineComponent({
  name: 'CommentsCard',
  components: {
    CommentReply,
  },
  props: {
    articleId: {
      type: String,
      dafault: '',
    },
  },
  setup(props, content) {
    let route = useRoute()
    let commentContent = ref('')
    let current = 1
    let size = 10
    let commentAllList = ref<any>([])
    let isNullComment = ref(false)
    let loading = ref(true)
    const inputRef=ref<InstanceType<typeof ElInput>>()

    const init = () => {
      current = 1
      size = 10
      commentAllList.value.length = 0
      isNullComment.value = false
      loading.value = false
    }

    async function getCommentFun() {
      loading.value=true
      let commentData!: IDataType
      commentData = await getComment({ articleId: route.params.id as string, current, size, orderBy: 'createTime.desc' })
      if (commentData.success) {
        if (commentData.data.pages === commentData.data.current) {
          isNullComment.value = true
        }
        commentAllList.value.push(...commentData.data.records)
        console.log(commentAllList.value)
        current++
        loading.value=false
      } else {
        message.warning(commentData.data)
      }
    }
    getCommentFun()

    const submitComment = () => {
      addCommentFun()
    }
    async function addCommentFun() {
      if (!props.articleId) {
        return
      } else if (!commentContent.value) {
        return message.warning('请输入评论内容')
      }
      const data = await addComment({
        articleId: props.articleId,
        commentContent: commentContent.value,
      })
      if (data.success) {
        message.success('评论成功')
        commentContent.value = ''
        init()
        getCommentFun() //刷新评论
      } else {
        message.warning(data.data)
      }
    }
    const wantComment=()=>{
       nextTick(()=>{
        inputRef.value?.focus()
      })
    }
    return {
      commentContent,
      submitComment,
      commentAllList,
      getCommentFun,
      isNullComment,
      inputRef,
      wantComment,
      loading
    }
  },
})
