import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ff83234"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Slide" }
const _hoisted_2 = { class: "imgBox" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_carousel, {
      interval: 2000,
      type: "card",
      height: "200px"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articleTopAll, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_carousel_item, {
            onClick: ($event: any) => (_ctx.$router.push(`/articleinfo/info/${item.articleId}`)),
            key: index
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  src: item.coverImg,
                  alt: "没有图片"
                }, null, 8, _hoisted_3),
                _createElementVNode("span", null, _toDisplayString(item.articleTitle), 1)
              ])
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}