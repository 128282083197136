import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-567f35e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ArticleTop5List" }
const _hoisted_2 = { class: "linkBox" }
const _hoisted_3 = { class: "active" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_link = _resolveComponent("el-link")!
  const _component_View = _resolveComponent("View")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Star = _resolveComponent("Star")!
  const _component_ChatDotRound = _resolveComponent("ChatDotRound")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "box",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(`/articleinfo/info/${_ctx.data.articleId}`)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_link, {
          class: "link",
          underline: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.articleTitle), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.type==='articleTopByViewsType')
          ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 0,
              class: "icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_View)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.type==='articleTopByStarsType')
          ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 1,
              class: "icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Star)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.type==='articleTopByCommentsType')
          ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 2,
              class: "icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ChatDotRound)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.num), 1)
      ])
    ])
  ]))
}