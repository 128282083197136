
import { defineComponent, ref } from 'vue'

import { formatUtcString } from '../../utils/date'

import ReplyOfComment from './ReplyOfComment.vue'

export default defineComponent({
  name: 'CommentReply',
  props: ['reply', 'comment', 'deep', 'isReply', 'present2'],
  components: {
    ReplyOfComment,
  },
  setup(props, content) {
    let present = ref({
      nickName: '',
    })
    let isDestroy = ref(false)
    //用户回复成功,渲染形成的虚拟评论
    let isShowVirComment = ref(false)
    let virReplyCommentList = ref<any>([])
    let replyInputContent = ref('')

    //该变量用来告诉页面更新数据
    let isDelVirCommentFlag=ref(1)
    //已经删除的虚拟列表
    let delVirCommentMap=ref(new Map())

    let isReplyInput = ref(false)
    const isReplyOk = (value: any) => {
      isShowVirComment.value = true
      present.value.nickName = value.byReplyer
      virReplyCommentList.value.push({
        commentContent: value.commentContent,
        createTime: value.createTime,
        commentId: value.user.commentId,
        owner: true,
        author: {
          headImgUrl: value.user.author.headImgUrl,
          nickName:value.user.author.nickName,
          userId:value.user.author.userId
        },
      })
    }
    // 监听删除评论事件
    const destroyComment = (Destroy: boolean) => {
      isDestroy.value = Destroy
    }
    //监听虚拟评论删除事件
    const VirReplyDel = (value: any) => {
      console.log(value)
      delVirCommentMap.value.set(value,value)
      isDelVirCommentFlag.value++
    }
    return {
      formatUtcString,
      isReplyInput,
      replyInputContent,
      isShowVirComment,
      isReplyOk,
      present,
      virReplyCommentList,
      isDestroy,
      destroyComment,
      VirReplyDel,
      isDelVirCommentFlag,
      delVirCommentMap
    }
  },
})
