import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d41afb26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Collect" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "img-box" }
const _hoisted_4 = { class: "info" }
const _hoisted_5 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.authorData)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "collect-box",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goAuthor && _ctx.goAuthor(...args)))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_avatar, {
                size: 50,
                src: _ctx.authorData.headImgUrl
              }, null, 8, ["src"])
            ]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.authorData.nickName), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, _toDisplayString(`被点赞 ${_ctx.collectData.starNum} 个`), 1),
            _createElementVNode("div", null, _toDisplayString(`被访问 ${_ctx.collectData.starNum} 次`), 1),
            _createElementVNode("div", null, _toDisplayString(`被评论 ${_ctx.collectData.commentNum} 条`), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}