
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ArticleCataItem',
  props: ['cataData'],
  emits:['cataItemEmit'],
  setup(props, content) {
      interface Tree {
        hName:string,
        label: string,
        hType:string,
        children?: Tree[]
    }
    const defaultProps = {
      children: 'children',
      label: 'label',
    }
    const clickCata = (data: Tree) => {
      content.emit('cataItemEmit', data.hName)
    }

    return{
        clickCata,
        defaultProps
    }
  },
})
