
import { defineComponent, ref, watchEffect, nextTick, onMounted, onUpdated, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { getArticleInfo } from '../../service/article/index'

import Prism from 'prismjs'

import message from '../../utils/message'
import { htmlToHNode } from '../../utils/htmlToHNode'
import type { IResponse } from '../../utils/htmlToHNode'
import type { ElScrollbar } from 'element-plus'
import {debounce} from '../../utils/debounce'

import ArticleLListCard from './ArticleLListCard.vue'
import ArticleInfoRCard from '../article/cnps/ArticleInfoRCard.vue'
import CommentsCard from '../comment/CommentsCard.vue'
import ArticleHeader from './ArticleHeader.vue'
import FooterNav from '../../components/Footer/FooterNav.vue'
import StarItem from './cnps/StarItem.vue'
import CommentItem from './cnps/CommentItem.vue'
import ArticleCataItem from './cnps/ArticleCataItem.vue'

import { Operation, Switch } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'ArticleInfo',
  components: {
    ArticleLListCard,
    CommentsCard,
    ArticleHeader,
    ArticleInfoRCard,
    FooterNav,
    StarItem,
    CommentItem,
    ArticleCataItem,
    Operation,
    Switch,
  },
  setup(props, content) {
    const router = useRouter()
    const route = useRoute()
    const scrollbarRef = ref<InstanceType<typeof ElScrollbar>>()
    const articleInfoRef=ref<HTMLDivElement>()
    let drawer = ref(false)
    let screenData: number = 0
    let screenHeight = ref(0)
    let isShowBackByComment = ref(false) //是否展示返回正文按钮
    let isFirst = true //首次根据保存的hash值滚动
    let articleData = ref({
      articleId: '',
    })
    let hTHNData = ref<IResponse>({
      data: {
        cataList: [],
        domStr: '',
      },
      msg: '',
      success: false,
    })
    async function articleInfo(id: any) {
      const data = await getArticleInfo(id)
      if (data.success) {
        articleData.value = data.data
        hTHNData.value = htmlToHNode(data.data.articleContent)
      } else {
        message.warning(data.data)
      }
    }
    articleInfo(route.params.id)
    onUpdated(() => {
      Prism.highlightAll()
       if (route.hash && isFirst && hTHNData.value.data.domStr!=='') {
          const hashStr = route.hash
          go(hashStr.substr(1, hashStr.length))
        }
    })
    //跳转锚点
    const go = (id: string) => {
      //设置hash值,便于保留位置
      nextTick(() => {
        let element = document.getElementById(id) as HTMLElement
        if(element){
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
          isFirst = false
          router.push(`/articleinfo/info/${articleData.value!.articleId}#${id}`)
        }
      })
    }
    //接收到目录组件点击的目录项
    const clickCataFun = (data: string) => {
      isFirst = false
      screenData = 0
      isShowBackByComment.value = false
      go(data)
    }
    const init = () => {
      isFirst = true
      screenData = 0
      isShowBackByComment.value = false
      router.push(`/articleinfo/info/${articleData.value!.articleId}`)
    }
    const scrollFun = (data: any) => {
      if (!isShowBackByComment.value) {
        screenData = data.scrollTop
      }
    }
    //获取屏幕高度
    const getScreenHight = () => {
      screenHeight.value = window.innerHeight
    }
    // const getScreenHightCheck=debounce(getScreenHight,1000,false)
    onMounted(() => {
      getScreenHight()
    })
    //从评论里返回到正文
    const backByComment = () => {
      isShowBackByComment.value = false
      scrollbarRef.value!.setScrollTop(Number(screenData))
    }
    //从正文去到评论
    const goCommentByArticle = () => {
      isFirst = false
      isShowBackByComment.value = true
      go('comment')
    }
    return {
      articleData,
      hTHNData,
      clickCataFun,
      drawer,
      init,
      backByComment,
      isShowBackByComment,
      goCommentByArticle,
      scrollFun,
      screenHeight,
      scrollbarRef,
      articleInfoRef
    }
  },
})
